<script setup lang="ts">
  import { computed, ref } from 'vue'
  import { useRoute } from 'vue-router'
  import { useCurrentUsersCompaniesQuery } from '@/api/useCompany'
  import IconArrowDown from '@/assets/icons/ArrowDown.svg'
  import { useAbility } from '@/modules/ability/composable/useAbility'
  import BaseLink from '@/modules/base/components/BaseLink.vue'
  import BaseModal from '@/modules/base/components/BaseModal.vue'
  import BasePopover from '@/modules/base/components/BasePopover.vue'
  import NestedDisclosure from '@/modules/base/components/NestedDisclosure.vue'
  import { buildTree } from '@/modules/base/composable/useTree'
  import LayoutButtonAdd from '@/modules/base/layouts/LayoutButtonAdd.vue'
  import LayoutScrollbarShade from '@/modules/base/layouts/LayoutScrollbarShade.vue'
  import { maybe } from '@/modules/base/utils/maybe'
  import CreateNewCompanyForm from './CreateNewCompanyForm.vue'

  const { data: companies } = useCurrentUsersCompaniesQuery({
    includeCurrentCompany: true,
    includeDescendantCompanies: true,
  })
  const companyTree = computed(() => maybe(buildTree, companies.value))

  const options = computed(() => {
    return (
      companies.value?.map((company) => ({
        depth: company.depth,
        title: company.name,
        to: {
          params: {
            companyId: company.id.toString(),
          },
        },
      })) ?? []
    )
  })

  const companyTitle = computed(() => {
    return companies.value?.slice(0, 1).shift()?.name
  })

  const createCompanyParentId = ref<number | null>(null)

  const route = useRoute()
  const title = computed(() => {
    return (
      options.value.find(({ to }) => {
        return to.params.companyId === route.params.companyId
      })?.title ?? options.value[0]?.title
    )
  })

  const openIds = ref(new Set<number>([]))

  const { can } = useAbility()
  const isMultiCompanyFeatureEnabled = computed(() => {
    return (
      (options.value && (companies.value?.length ?? 0) > 1) ||
      can('create', 'Company')
    )
  })
</script>

<template>
  <BasePopover
    v-if="isMultiCompanyFeatureEnabled"
    button-class="flex items-center py-control-block px-control-inline text-xl"
  >
    <template #button="{ open }">
      {{ title }}
      <IconArrowDown
        class="ml-1 fill-current"
        :class="[open ? '-scale-y-100' : 'scale-y-100']"
      />
    </template>
    <template #panel="{ close }">
      <div class="overflow-hidden rounded-[inherit]">
        <LayoutScrollbarShade class="text-black-var pr-1">
          <div
            class="gc-global-scrollbar gc-global-scrollbar-thin gc-global-scrollbar-white-var-30 text-white-var max-h-[30rem] overflow-y-auto px-3"
          >
            <div v-focus class="w-80" tabindex="0" />

            <NestedDisclosure
              v-if="companyTree"
              v-slot="{ item: { item: company } }"
              v-model:open-ids="openIds"
              :nested-item="companyTree"
            >
              <div class="flex w-full items-center justify-between">
                <BaseLink
                  class="py-control-block pl-0"
                  data-testid="header-company-switcher-link"
                  :to="{ params: { companyId: company.id } }"
                  @click="close"
                >
                  {{ company.name }}
                </BaseLink>
                <LayoutButtonAdd
                  v-if="$can('create', 'Company')"
                  v-tooltip="{
                    content: $t('global.header.createCompanyTooltip'),
                    options: { placement: 'right' },
                  }"
                  data-testid="header-company-switcher-create-company"
                  icon-class="size-4"
                  @click="createCompanyParentId = company.id"
                />
              </div>
            </NestedDisclosure>
          </div>
        </LayoutScrollbarShade>
      </div>
    </template>
  </BasePopover>

  <div v-else>
    <h1 class="py-control-block px-6 text-xl">
      {{ companyTitle }}
    </h1>
  </div>
  <BaseModal
    v-if="$can('create', 'Company')"
    v-slot="{ close }"
    container-class="!w-100"
    :open="createCompanyParentId != null"
    @close="createCompanyParentId = null"
  >
    <CreateNewCompanyForm
      v-if="createCompanyParentId != null"
      :parent-company-id="createCompanyParentId"
      @close="close"
    />
  </BaseModal>
</template>
