import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useLayoutStore = defineStore('layout', () => {
  const isMenuOpen = ref(true)
  const setIsMenuOpen = (to: boolean) => {
    isMenuOpen.value = to
  }
  const isMainLayoutMounted = ref(false)

  return {
    isMenuOpen,
    setIsMenuOpen,
    isMainLayoutMounted,
  }
})
