<script setup lang="ts">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { RouteLocationRaw, useLink } from 'vue-router'
  import BaseLink from '@/modules/base/components/BaseLink.vue'

  const props = withDefaults(
    defineProps<{
      to: RouteLocationRaw
      submenu?: boolean
      matchExact?: boolean
    }>(),
    {
      matchExact: false,
      submenu: false,
    }
  )

  const { isActive, isExactActive } = useLink({ to: props.to })
  const isActiveMatchBased = computed(() =>
    props.matchExact ? isExactActive.value : isActive.value
  )

  const { t } = useI18n()
  const defaultName = computed(() => {
    if (typeof props.to === 'object' && 'name' in props.to) {
      return t(`global.view.${String(props.to.name)}`)
    }
    return ''
  })
</script>

<template>
  <BaseLink
    class="-mx-control-inline col-span-full grid grid-cols-subgrid items-center gap-y-1 py-0.5"
    :class="{
      'text-cyan': isActiveMatchBased,
    }"
    data-testid="menu-link"
    :to
  >
    <div
      :class="{
        'text-gray-var-400': !isActiveMatchBased,
      }"
    >
      <slot name="icon" />
    </div>
    <div class="grid">
      <!-- Always display both font weights to avoid layout shift when switching between bold and light styles -->
      <span
        class="col-span-full row-span-full font-bold"
        :class="{
          invisible: submenu && !isActiveMatchBased,
        }"
        data-testid="menu-link-title-bold"
      >
        <slot>{{ defaultName }}</slot>
      </span>
      <span
        class="col-span-full row-span-full font-light"
        :class="{
          invisible: !submenu || isActiveMatchBased,
        }"
        data-testid="menu-link-title-light"
      >
        <slot>{{ defaultName }}</slot>
      </span>
    </div>
    <hr
      class="border-cyan -mr-control-inline col-span-2"
      :class="{ invisible: !isActiveMatchBased }"
    />
  </BaseLink>
</template>
