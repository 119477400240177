import { z } from 'zod'

export const bannerSchema = z.strictObject({
  current: z
    .strictObject({
      id: z.number(),
      title: z.string(),
      message: z.string(),
      link: z
        .strictObject({
          text: z.string(),
          href: z.string(),
        })
        .nullable(),
    })
    .nullable(),
})

export type Banner = z.infer<typeof bannerSchema>

export interface BannerInteraction {
  kind: BannerInteractionKind
}

export enum BannerInteractionKind {
  Dismissed = 'dismissed',
  LinkClicked = 'link_clicked',
}
