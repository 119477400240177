<script setup lang="ts">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  import { useAuth } from '@/api/useAuth'
  import { useCurrentCompanyQuery } from '@/api/useCompany'
  import IconAccount from '@/assets/icons/Account.svg'
  import IconLogout from '@/assets/icons/Logout.svg'
  import { MenuOption } from '@/modules/base/components/baseMenu'
  import BaseMenuDropdown from '@/modules/base/components/BaseMenuDropdown.vue'
  import BaseUserAvatar from '@/modules/base/components/BaseUserAvatar.vue'
  import { useCurrentUser } from '@/modules/base/composable/useCurrentUser'
  import { Route } from '@/modules/base/config/routesConfig'
  import { useResetOnboarding } from '@/modules/settings/composable/useResetOnboarding'
  import { ContactInfo } from '@/modules/shared/config/contactInfoConfig'

  const { currentUser } = useCurrentUser()
  const { logout } = useAuth()

  const { t } = useI18n()
  const { data: company } = useCurrentCompanyQuery()
  const { resetOnboardingWithConfirmation } = useResetOnboarding()
  const router = useRouter()
  const options = computed<MenuOption[]>(() => [
    {
      title: t('global.header.myAccount'),
      action: () => router.push({ name: Route.SettingsMyAccount }),
      icon: IconAccount,
    },
    {
      title: t('global.header.logout'),
      action: logout,
      icon: IconLogout,
    },
    {
      separator: true,
    },
    {
      title: t('global.header.sendEmail'),
      action: () => window.open(ContactInfo.EmailTo, '_blank'),
    },
    {
      title: t('global.header.scheduleCall'),
      action: () => window.open(ContactInfo.CalendlyUrl, '_blank'),
    },
    {
      title: t('global.header.termsAndConditions'),
      action: () =>
        window.open(t('global.header.termsAndConditionsLink'), '_blank'),
    },
    {
      title: t('global.header.privacyPolicy'),
      action: () => window.open(t('global.header.privacyPolicyLink'), '_blank'),
    },
    ...(company.value?.hasResetOnboarding
      ? [
          {
            title: t('global.menu.resetOnboarding'),
            action: resetOnboardingWithConfirmation,
          },
        ]
      : []),
  ])
</script>

<template>
  <BaseMenuDropdown :options panel-class="w-60" toggle-class="p-1.5">
    <BaseUserAvatar
      v-if="currentUser"
      class="h-9"
      data-testid="the-header-user-menu"
      :user-id="currentUser?.id"
    />
  </BaseMenuDropdown>
</template>
