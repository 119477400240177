import { useCssVar } from '@vueuse/core'
import { computed, Ref, unref } from 'vue'
import { useRoute } from 'vue-router'
import { Route } from '@/modules/base/config/routesConfig'
import LayoutAppDefault from '@/modules/base/layouts/LayoutAppDefault.vue'
import LayoutAppNavigationHidden from '@/modules/base/layouts/LayoutAppNavigationHidden.vue'

export enum RootProperty {
  InsightsTableControlsHeight = 'insights-table-controls-height',
}

/**
 * Will set css property on a root element
 * @param key key for css property without --gc-global- prefix (it'll be added automatically)
 */
export const useRootProperty = (key: RootProperty | Ref<RootProperty>) => {
  const property = useCssVar(computed(() => `--gc-global-${unref(key)}`))
  return { property }
}

export const useLayoutApp = () => {
  const route = useRoute()

  const id = computed(() => {
    if (
      route.matched.some(
        ({ name }) => name === Route.Onboarding || name === Route.NotOnboarded
      )
    ) {
      return 'navigationHidden'
    }

    return 'default'
  })

  const component = computed(() => {
    if (id.value === 'navigationHidden') {
      return LayoutAppNavigationHidden
    }
    return LayoutAppDefault
  })

  return {
    id,
    component,
  }
}
