<script setup lang="ts">
  import {
    Feature,
    useFeatures,
  } from '@/modules/ability/composable/useFeatures'
  import { Route } from '@/modules/base/config/routesConfig'
  import MdiListBoxOutline from '~icons/mdi/list-box-outline'
  import MenuLink from './MenuLink.vue'

  const { features } = useFeatures()
</script>

<template>
  <MenuLink
    data-testid="the-menu-link-reporting"
    match-exact
    :to="{ name: Route.Reporting }"
  >
    <template #icon>
      <MdiListBoxOutline class="size-6" />
    </template>
  </MenuLink>
  <MenuLink
    v-if="$can('show', features, Feature.ReportingMateriality)"
    data-testid="the-menu-link-reporting-materiality"
    submenu
    :to="{ name: Route.Materiality }"
  />
</template>
