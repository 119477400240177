<script setup lang="ts">
  import { computed } from 'vue'
  import IconLogoBlackText from '@/assets/themes/default/LogoBlackText.svg?url'
  import IconLogoSquare from '@/assets/themes/default/LogoSquare.svg?url'
  import IconLogoWhiteText from '@/assets/themes/default/LogoWhiteText.svg?url'
  import IconLogoBlackTextGZ from '@/assets/themes/greenzero/LogoBlackTextGZ.svg?url'
  import IconLogoSquareGZ from '@/assets/themes/greenzero/LogoGZ.svg?url'
  import IconLogoWhiteTextGZ from '@/assets/themes/greenzero/LogoWhiteTextGZ.svg?url'
  import { useCurrentUser } from '@/modules/base/composable/useCurrentUser'

  interface LogoTheme {
    dark: string
    light: string
    square: string
  }

  const DEFAULT_THEME: LogoTheme = {
    dark: IconLogoWhiteText,
    light: IconLogoBlackText,
    square: IconLogoSquare,
  }

  // Mapping: "hostname" => theme
  const THEMES: { [key: string]: LogoTheme } = {
    'center.greenzero.eu': {
      dark: IconLogoWhiteTextGZ,
      light: IconLogoBlackTextGZ,
      square: IconLogoSquareGZ,
    },
  }

  defineProps<{
    square: boolean
  }>()

  // The hostname does not change during runtime and thus no need for reactivity
  const currentTheme = THEMES[location.hostname] ?? DEFAULT_THEME

  const { currentUser } = useCurrentUser()

  const logoThemed = computed(() => {
    return currentUser.value?.useLightTheme
      ? currentTheme.light
      : currentTheme.dark
  })
</script>

<template>
  <div class="h-[26px] w-full">
    <img
      v-show="!square"
      class="h-full max-w-[118px]"
      data-testid="app-logo-with-text"
      :src="logoThemed"
    />
    <img
      v-show="square"
      class="h-full max-w-[26px]"
      data-testid="app-logo-square"
      :src="currentTheme.square"
    />
  </div>
</template>
