import { useMutation } from '@tanstack/vue-query'
import { Ref } from 'vue'
import { BannerInteraction, bannerSchema } from './useBanners.types'
import {
  composeQuery,
  createMutationFn,
  useComposeInvalidation,
} from './utils/factory'
import { Endpoint, useUrl } from './utils/url'

export const useQueryBannersCurrent = composeQuery(bannerSchema, [
  Endpoint.Banners,
  Endpoint.BannersCurrent,
])

export const useMutationBannerInteract = (bannerId: Ref<number | null>) => {
  const { createUrl } = useUrl()
  const { invalidate } = useBannersInvalidation()

  return useMutation({
    mutationFn: createMutationFn<{ interaction: BannerInteraction }>({
      method: 'POST',
      path: () =>
        createUrl(Endpoint.Banners, bannerId, Endpoint.BannersInteractions),
      body: (variables) => variables,
    }),
    onSettled: invalidate,
  })
}

const useBannersInvalidation = useComposeInvalidation(() => [
  [Endpoint.Banners, Endpoint.BannersCurrent],
])
