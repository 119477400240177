<script setup lang="ts" generic="T extends { id: number }">
  import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  } from '@headlessui/vue'
  import { computed } from 'vue'
  import IconArrowDown from '@/assets/icons/ArrowDown.svg?component'
  import { ItemWithChildren } from '../composable/useTree'

  const openIds = defineModel<Set<number>>('openIds', {
    required: true,
  })

  const props = withDefaults(
    defineProps<{
      nestedItem: ItemWithChildren<T>
      root?: boolean
    }>(),
    {
      root: true,
    }
  )

  const hasChildren = computed(() => props.nestedItem.children.length > 0)
  const isOpen = computed(() => openIds.value.has(props.nestedItem.item.id))
  const toggleOpen = () => {
    const id = props.nestedItem.item.id

    const openIdsClone = new Set(openIds.value)
    if (isOpen.value) {
      openIdsClone.delete(id)
    } else {
      openIdsClone.add(id)
    }
    openIds.value = openIdsClone
  }
</script>

<template>
  <Disclosure
    v-if="hasChildren"
    v-slot="{ open }"
    :default-open="isOpen || root"
  >
    <div class="flex items-center gap-2.5">
      <DisclosureButton
        v-if="!root"
        class="-m-1 rounded-full p-1"
        data-testid="nested-disclosure-button"
        @click="toggleOpen"
      >
        <IconArrowDown
          class="text-white-var size-5"
          :class="{ '-rotate-90': !open }"
        />
      </DisclosureButton>
      <slot :item="nestedItem" />
    </div>
    <DisclosurePanel
      :class="{
        'border-l-gray-var-600 ml-2.5 border-l pl-2.5': !root,
      }"
    >
      <template v-for="child in nestedItem.children" :key="child.item.id">
        <NestedDisclosure
          v-slot="{ item }"
          v-model:open-ids="openIds"
          :nested-item="child"
          :root="false"
        >
          <slot :item />
        </NestedDisclosure>
      </template>
    </DisclosurePanel>
  </Disclosure>
  <div v-else class="flex items-center gap-2.5 border-l border-transparent">
    <IconArrowDown class="text-white-var invisible size-5" />
    <slot :item="nestedItem" />
  </div>
</template>
