import { useI18n } from 'vue-i18n'
import { resetOnboarding } from '@/api/useCompany'
import { useConfirmation } from '@/modules/base/composable/useConfirmation'

export const useResetOnboarding = () => {
  const { t } = useI18n()
  const { showConfirmation } = useConfirmation()

  const resetOnboardingWithConfirmation = () => {
    showConfirmation({
      content: t('global.menu.formResetOnboarding.header'),
      labelConfirm: t('global.menu.resetOnboarding'),
      labelCancel: t('global.form.cancel'),
      onConfirm: resetOnboarding,
    })
  }

  return {
    resetOnboardingWithConfirmation,
  }
}
