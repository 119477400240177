<template>
  <div class="relative flex h-screen flex-col overflow-auto">
    <header class="mx-16 mb-24 mt-5">
      <h1><span class="sr-only">Global Changer</span></h1>
      <img
        alt="CCC logo"
        class="h-10"
        src="@/assets/icons/CCCLogo.svg?noTransform"
      />
    </header>
    <main class="flex max-h-full w-full flex-1 flex-col">
      <slot />
    </main>
  </div>
</template>
