import { defineStore } from 'pinia'

export const useBannerSharedStore = defineStore('app-banner-store', {
  state: () => ({
    show: true,
  }),
  share: {
    enable: true,
  },
})
